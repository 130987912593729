import { DefaultScreenCodeResolver } from '@ui-tool/core';
import { Injectable } from '@angular/core';
import { ScreenCodes } from '@main-data-access-enums';

@Injectable()
export class ScreenResolver extends DefaultScreenCodeResolver {
  //#region Constructor

  constructor() {
    const codeToUrl: Record<string, string> = {};
    codeToUrl[ScreenCodes.DASHBOARD] = '/dashboard';
    codeToUrl[ScreenCodes.UNAVAILABLE_USER] =
      '/authentication/unavailable-user';
    codeToUrl[ScreenCodes.LOGIN] = '/authentication';

    codeToUrl[ScreenCodes.PATIENT_DETAIL] = '/patient/{{mrn}}';

    codeToUrl[ScreenCodes.PATIENT_APPOINTMENT] = '/patient/{{mrn}}/appointment';

    codeToUrl[ScreenCodes.PATIENT_MEDICATION] = '/patient/{{mrn}}/medication';

    codeToUrl[ScreenCodes.PATIENT_TREATMENT] = '/patient/{{mrn}}/treatment';

    codeToUrl[ScreenCodes.PATIENT_SYMPTOM] = '/patient/{{mrn}}/symptom';

    codeToUrl[ScreenCodes.PATIENT_CLINICIAN] = '/patient/{{mrn}}/clinician';

    codeToUrl[ScreenCodes.PATIENTS] = '/patient';
    codeToUrl[ScreenCodes.USERS] = '/user';
    codeToUrl[ScreenCodes.CLINICIANS] = '/clinicians';

    codeToUrl[ScreenCodes.UNDER_MAINTENANCE] = '/error/under-maintenance';

    super(codeToUrl);
  }

  //#endregion

  //#region Methods

  public override loadUrl(code: string): string | null {
    return this._codeToUrl[code];
  }

  //#endregion
}
