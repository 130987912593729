import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { DSSelectItemDirective, DSSelectPrefixDirective } from './directives';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DSControlDirective, DSControlTypes } from '../control';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { cloneDeep, isEqual } from 'lodash-es';
import { IDSSelectRenderTemplateResolver } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { DSSelectFilterBys, DSSelectFilterModes } from './enums';

@Component({
  selector: 'm-ocloud-ds-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DSSelectComponent),
      multi: true,
    },
    {
      provide: DSControlDirective,
      useExisting: DSSelectComponent,
    },
  ],
  host: {
    '[class]': '"contents"',
  },
})
export class DSSelectComponent<TType extends any>
  extends DSControlDirective<TType | TType[] | null>
  implements ControlValueAccessor
{
  //#region Properties

  @ContentChild(DSSelectPrefixDirective)
  public readonly prefixTemplate: DSSelectPrefixDirective | null = null;

  @ContentChildren(DSSelectItemDirective)
  public set itemTemplates(
    itemTemplates: QueryList<DSSelectItemDirective<TType>> | null
  ) {
    this.listItemTemplates = Array.from(itemTemplates || []).filter(
      (item) => !!item
    );
    this._cdr.markForCheck();
  }

  @Output()
  public readonly filterKeyChange = new EventEmitter<string>();

  @Input()
  public value: TType | TType[] | null = null;

  @Input()
  public hasError = false;

  @Input()
  public disabled = false;

  @Input()
  public readOnly = false;

  @Input()
  public fixedWidth = false;

  @Input()
  public offsetY = 8;

  @Input()
  public offsetX = 0;

  @Input()
  public positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 8,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: 0,
    },
  ];

  @Input()
  public maxHeight = 'auto';

  @Input()
  public customClasses = '';

  @Input()
  public isMultiple = false;

  @Input()
  public closeOnSelected = true;

  @Input()
  public filterKey = '';

  @Input()
  public filterPlaceHolder = '';

  @Input()
  public filterMode: DSSelectFilterModes | `${DSSelectFilterModes}` | null =
    null;

  @Input()
  public filterBy: DSSelectFilterBys | `${DSSelectFilterBys}` =
    DSSelectFilterBys.INSIDE;

  @Input()
  public autocomplete = false;

  @Input()
  public placeHolder = '';

  @Input()
  public renderTemplateResolver: Partial<
    IDSSelectRenderTemplateResolver<TType>
  > | null = null;

  @Output()
  public readonly itemSelected = new EventEmitter<TType | TType[] | null>();

  public readonly value$ = new BehaviorSubject<TType | TType[] | null>(null);

  public readonly controlType = DSControlTypes.SELECT;

  public onTouchedCallback: () => void = () => {};

  public onChangeCallback: (value: TType | TType[] | null) => void = () => {};

  public isOpen = false;

  //#endregion

  //#region Accessors

  public get isDisabled(): boolean {
    return this.disabled;
  }

  public get isReadonly(): boolean {
    return this.readOnly;
  }

  public get isPlaceholder(): boolean {
    if (!this.value) {
      return true;
    }

    return this.isMultiple ? !(this.value as TType[] | null)?.length : false;
  }

  public listItemTemplates: DSSelectItemDirective<TType>[] = [];

  public get renderTemplate(): string {
    if (!this.renderTemplateResolver) {
      return this.renderValue;
    }

    const { singleResolver, multipleResolver } = this.renderTemplateResolver;
    if (this.isMultiple) {
      return multipleResolver
        ? multipleResolver(this.value as TType[] | null, this.listItemTemplates)
        : this.renderValue;
    } else {
      return singleResolver
        ? singleResolver(this.value as TType | null, this.listItemTemplates)
        : this.renderValue;
    }
  }

  public get renderValue(): string {
    if (this.isMultiple) {
      return this.listItemTemplates
        .filter((item) =>
          ((this.value as TType[]) || []).includes(item.value as TType)
        )
        .map((item) => item.text)
        .join(', ');
    } else {
      return (
        this.listItemTemplates.find(
          (item) => item.value === (this.value as TType | null)
        )?.text || (typeof this.value === 'string' ? this.value : '')
      );
    }
  }

  //#endregion

  //#region Constructor

  public constructor(protected readonly _cdr: ChangeDetectorRef) {
    super();
  }

  //#endregion

  //#region Methods

  public registerOnChange(fn: (value: TType | TType[] | null) => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: TType | TType[] | null): void {
    if (isEqual(value, this.value)) {
      return;
    }

    this.value = value;
    this.value$.next(value);
    this.onChangeCallback(value);
    this.onTouchedCallback();

    this._cdr.markForCheck();
  }

  public selectItem(value: TType | TType[] | null): void {
    if (this.isMultiple) {
      let values = cloneDeep(this.value || []) as TType[];
      if (values.includes(value as TType)) {
        values = values.filter((v) => !isEqual(v, value));
      } else {
        values.push(value as TType);
      }
      this.writeValue(values);
      this.itemSelected.emit(values);
      return;
    }

    this.writeValue(value);
    this.itemSelected.emit(value);
  }

  //#endregion
}
