import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { IDSDatePickerRange } from '../../interfaces';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'm-ocloud-ds-date-picker-single',
  templateUrl: './date-picker-single.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerSingleComponent implements OnChanges {
  //#region Properties

  @Output()
  public readonly cancelClick = new EventEmitter();

  @Output()
  public readonly applyClick = new EventEmitter();

  @Output()
  public readonly valueChange = new EventEmitter<Date | null>();

  @Input()
  public value: Date | null = null;

  @Input()
  public text = '';

  @Input()
  public isPlaceholder = false;

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  @Input()
  public live: IDSDatePickerRange = {
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  };

  public defaultValue: Date | null = null;
  public current = new Date();

  //#endregion

  //#region Constructor

  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  //#endregion

  //#region Life cycle hooks

  public ngOnChanges(): void {
    this.defaultValue = cloneDeep(this.value);
    this.current = this.value ? cloneDeep(this.value) : new Date();
    this._cdr.markForCheck();
  }

  //#endregion

  //#region Methods

  public selectDate(value: Date): void {
    this.value = value;
    this.current = value;
    this._cdr.markForCheck();
  }

  public confirmDate(): void {
    this.valueChange.emit(this.value);
    this.applyClick.emit();
  }

  public cancel(): void {
    this.value = cloneDeep(this.defaultValue);
    this.valueChange.emit(this.value);
    this.cancelClick.emit();
  }

  public todayClick(): void {
    this.selectDate(new Date());
  }

  //#endregion
}
