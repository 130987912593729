import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  finalize,
  map,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { IClinicianSearch } from '@main-data-access-interfaces';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { DOCTOR_SERVICE, IDoctorService } from '@main-data-access-services';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';
import { ICliniciansComponentState } from './clinicians.state';
import { ClinicianStatuses, ClinicianTypes } from '@main-data-access-enums';

@Injectable()
export class CliniciansComponentStore extends ComponentStore<ICliniciansComponentState> {
  //#region Properties

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion Properties

  //#region Constructor

  public constructor(
    @Inject(DOCTOR_SERVICE) protected readonly _doctorService: IDoctorService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService
  ) {
    super({
      searchResult: { records: [], total: 0 },
      searchCondition: {
        name: '',
        type: ClinicianTypes.ALL,
        status: ClinicianStatuses.ALL,
        pager: {
          page: 1,
          limit: 10
        },
      },
      loadCliniciansProcesses: 1,
    });
  }

  //#endregion Constructor

  //#region Methods

  public readonly updatePage = this.updater<
    ICliniciansComponentState['searchCondition']['pager']
  >((state, pager) => ({
    ...state,
    searchCondition: {
      ...state.searchCondition,
      pager,
    },
  }));

  public readonly updateSearch = this.updater<
    ICliniciansComponentState['searchCondition']
  >((state, search) => ({
    ...state,
    searchCondition: {
      ...state.searchCondition,
      ...search,
      pager: {
        page: 1,
        limit: 10
      },
    },
  }));

  public readonly loadDoctors = this.effect<
    (data: ICliniciansComponentState['searchResult']) => void
  >(
    pipe(
      switchMap((cb) =>
        combineLatest([
          this.select((state) => state.searchCondition),
          this._refresh$,
        ]).pipe(
          map(([search]) => {
            Object.keys(search).forEach((key) => {
              const value = search[key as keyof IClinicianSearch];
              if (value == null || value == '') {
                delete search[key as keyof IClinicianSearch];
              }

              if (
                (key === 'status' || key === 'type') &&
                value === 'ALL'
              ) {
                delete search[key as keyof IClinicianSearch];
              }
            });
            return { cb, search };
          })
        )
      ),
      debounceTime(250),
      switchMap(({ cb, search }) => {
        this.patchState({
          loadCliniciansProcesses: this.get().loadCliniciansProcesses + 1,
        });
        return this._doctorService.getCliniciansAsync(search).pipe(
          tap((data) => {
            cb(data);

            const pager = this.get().searchCondition.pager;
            const page = pager?.page ?? 1;
            const limit = pager?.limit ?? 10;
            this.patchState({
              searchResult: {
                ...data,
                records: data.records.map((item, index) => ({
                  ...item,
                  numericalOrder: (page - 1) * limit + index + 1,
                })),
              },
            });
          }),
          catchError((exception) => {
            this._alertService.error('Error', exception.message);
            return EMPTY;
          }),
          finalize(() => {
            this.patchState({
              loadCliniciansProcesses: this.get().loadCliniciansProcesses - 1,
            });
          })
        );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}
