export enum RequirementNames {
  ABLE_TO_VIEW_PATIENT_DETAILS = 'ABLE_TO_VIEW_PATIENT_DETAILS',
  ABLE_TO_VIEW_APPOINTMENTS = 'ABLE_TO_VIEW_APPOINTMENTS',
  ABLE_TO_VIEW_DIAGNOSES = 'ABLE_TO_VIEW_DIAGNOSES',
  ABLE_TO_VIEW_APPOINTMENT_HISTORIES = 'ABLE_TO_VIEW_APPOINTMENT_HISTORIES',
  ABLE_TO_VIEW_USERS = 'ABLE_TO_VIEW_USERS',
  ABLE_TO_VIEW_PATIENTS = 'ABLE_TO_VIEW_PATIENTS',
  ABLE_TO_VIEW_DASHBOARD = 'ABLE_TO_VIEW_DASHBOARD',
  ABLE_TO_VIEW_CLINICIANS = 'ABLE_TO_VIEW_CLINICIANS',
}
