<section class="ds-date-picker-single">
  <header>
    <m-ocloud-ds-date-picker-navigation
      [(value)]="current"
      [min]="min"
      [max]="max"
    ></m-ocloud-ds-date-picker-navigation>
  </header>
  <main>
    <m-ocloud-ds-date-picker-value
      [type]="'single'"
      [text]="text"
      [isPlaceholder]="isPlaceholder"
      (todayClick)="todayClick()"
    ></m-ocloud-ds-date-picker-value>
    <m-ocloud-ds-date-picker-date
      [value]="value"
      [type]="'single'"
      [min]="min"
      [max]="max"
      (selectedDate)="selectDate($event)"
      [date]="current"
    ></m-ocloud-ds-date-picker-date>
  </main>
  <footer>
    <m-ocloud-ds-date-picker-actions
      (applyClick)="confirmDate()"
      (cancelClick)="cancel()"
    ></m-ocloud-ds-date-picker-actions>
  </footer>
</section>
